// console.log('sitewide javascript works!')

// Get browserwindow height for usage in CSS vars
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

// Booklet Toggler
// const bookletToggle = document.getElementById('switch');
// let bookletLabel = 'booklet';
// if (bookletToggle) {
//   bookletToggle.innerText = bookletLabel;
//   bookletToggle.addEventListener('click', function () {
//     document.body.classList.toggle('booklet');
//     bookletLabel === 'booklet' ? bookletLabel = 'site' : bookletLabel = 'booklet';
//     bookletToggle.innerText = bookletLabel;
//   })
// };
    
// Menu toggler
const menuToggler = document.querySelector('.menutoggler');
const mainMenu = document.querySelector('.mainmenu');
// toggle menu using icon
menuToggler.addEventListener('click', function (e) {
  e.stopPropagation();
  toggleMenu();
});
// dismiss menu clicking outside it
document.documentElement.addEventListener('click', function () {
  if (!menuToggler.classList.contains('collapsed')) {
    toggleMenu(menuToggler);
  };
});

function toggleMenu(e) {
  menuToggler.classList.toggle('collapsed');
  mainMenu.classList.toggle('hidden');
};

// Sticky scroll when the element is child of body
// onscroll can only be used on one instance per page
const stickyHeader = document.querySelector('.topbar');
const stickyDistance = stickyHeader.offsetTop;
window.onscroll = function (e) {
  if (window.scrollY > stickyDistance) {
    stickyHeader.classList.add('is-sticky');
  } else {
    stickyHeader.classList.remove('is-sticky');
  }
};

// // Sticky scroll when the element is child of parallax wrapper
// const scrollWrapper = document.querySelector('.siteheader');
// const stickyHeader = document.querySelector('.topbar');
// scrollWrapper.addEventListener('scroll', function (e) {
//   // console.log(scrollWrapper.scrollTop); // check
//   if (scrollWrapper.scrollTop > 0) {
//     stickyHeader.classList.add('is-sticky');
//   } else {
//     stickyHeader.classList.remove('is-sticky');
//   }
// });

// Modal
// https://jasonwatmore.com/post/2023/01/04/vanilla-js-css-modal-popup-dialog-tutorial-with-example
const openModalBtn = document.getElementById('openModal');
openModalBtn ? openModalBtn.addEventListener('click', openModal) : '';
const closeModalBtn = document.getElementById('closeModal');
closeModalBtn ? closeModalBtn.addEventListener('click', closeModal) : '';

function openModal() {
  // console.log('open modal')
  document.getElementById('signupModal').classList.add('is-open');
  document.body.classList.add('modal-active');
}

function closeModal() {
  // console.log('close modal')
  document.querySelector('.modal.is-open').classList.remove('is-open');
  document.body.classList.remove('modal-active');
}

// dismiss modal clicking outside it
document.documentElement.addEventListener('click', event => {
  if (event.target.classList.contains('modal')) {
    closeModal();
  }
});

// dismiss modal clicking ESC key
document.documentElement.addEventListener('keydown', event => {
  if (event.key === 'Escape') {
    closeModal();
  }
});

// Glider
// window.addEventListener('load', function () {
//   new Glider(document.querySelector('.glider'), {
//     slidesToShow: 1,
//     dots: '.dots',
//     draggable: true,
//     rewind: true
//   })
// })

// Swiper
// https://swiperjs.com/get-started
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
  // loop: true,
  speed: 1000,
  autoplay: {
    delay: 5000,
    stopOnLastSlide: true,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// Simple Parallax
// https://simpleparallax.com/
import simpleParallax from 'simple-parallax-js';

const images = document.querySelectorAll('.parallax img, .carousel img');
new simpleParallax(images, {
  // scale: 1.5
});

// Accordion
// https://www.aditus.io/patterns/accordion/
const accordionHeaders = document.querySelectorAll('data-accordion-header');
Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
  let target = accordionHeader.parentElement.nextElementSibling;
  accordionHeader.onclick = () => {
    let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
    accordionHeader.setAttribute('aria-expanded', !expanded);
    target.hidden = expanded;
  }
});

// Carousel
// option 1: simple
// https://webdesign.tutsplus.com/tutorials/how-to-build-a-simple-carousel-with-vanilla-javascript--cms-41734
// const slideContainer = document.getElementById('carousel-1-container');
// const slide = document.querySelector('.carousel-1-slide');
// const nextButton = document.getElementById('carousel-1-pagination-next');
// const prevButton = document.getElementById('carousel-1-pagination-prev');
// // console.log(slideContainer, slide, nextButton, prevButton); // check
// nextButton.addEventListener('click', (event) => {
//   const slideWidth = slide.clientWidth;
//   slideContainer.scrollLeft += slideWidth;
// });
// prevButton.addEventListener('click', () => {
//   const slideWidth = slide.clientWidth;
//   slideContainer.scrollLeft -= slideWidth;
// });

// option 2: more advanced
// https://medium.com/@marcusmichaels/how-to-build-a-carousel-from-scratch-in-vanilla-js-9a096d3b98c9
// variables to target our base class,
// get carousel items,
// count how many carousel items there are,
// set the slide to 0 (which is the number that tells us the frame we're on),
// set motion to true which disables interactivity

// // TODO: use unique id for each carousel otherwise counting slides will be off!
// let itemClassName = 'carousel-2-slide',
//   items = document.getElementsByClassName(itemClassName),
//   totalItems = items.length,
//   slide = 0,
//   moving = true;

// // to initialise the carousel we'll want to update the DOM with our own classes
// function setInitialClasses() {
//   // target the last, initial, and next items and give them the relevant class
//   // this assumes there are three or more items
//   items[totalItems - 1].classList.add('prev');
//   items[0].classList.add('active');
//   items[1].classList.add('next');
// }

// // set click events to navigation buttons
// function setEventListeners() {
//   const next = document.querySelector('.carousel-2-pagination-next'),
//     prev = document.querySelector('.carousel-2-pagination-prev');

//   next.style.opacity = '0.5';
//   prev.style.opacity = '0.5';
//   next.addEventListener('click', moveNext);
//   prev.addEventListener('click', movePrev);
// }

// // disable interaction by setting 'moving' to true for the same duration as the transition (0.5s = 500ms)
// function disableInteraction() {
//   moving = true;
//   // console.log('disabled interaction');

//   setTimeout(function () {
//     moving = false
//   }, 500);
//   // console.log('end disabled interaction');
// }

// function moveCarouselTo(slide) {
//   // console.log('move carousel');

//   // check if carousel is moving, if not, allow interaction
//   if (!moving) {
//     // console.log('start move');
//     console.log(`slide ${slide}`);

//     // temporarily disable interactivity
//     disableInteraction();

//     // Preemptively set variables for the current next and previous slide, as well as the potential next or previous slide.
//     let newPrevious = slide - 1,
//       newNext = slide + 1,
//       oldPrevious = slide - 2,
//       oldNext = slide + 2;

//     // test if carousel has more than three items
//     if (totalItems >= 3) {

//       // console.log('more than 3 items');

//       // checks if the new potential slide is out of bounds and sets slide numbers
//       if (newPrevious <= 0) {
//         oldPrevious = (totalItems - 1);
//       } else if (newNext >= (totalItems - 1)) {
//         oldNext = 0;
//       }

//       // check if current slide is at the beginning or end and sets slide numbers
//       if (slide === 0) {
//         newPrevious = (totalItems - 1);
//         oldPrevious = (totalItems - 2);
//         oldNext = (slide + 1);
//       } else if (slide === (totalItems - 1)) {
//         newPrevious = (slide - 1);
//         newNext = 0;
//         oldNext = 1;
//       }

//       // now we've worked out where we are and where we're going, by adding and removing classes, we'll be triggering the carousel's transitions.

//       // based on the current slide, reset to default classes.
//       console.log('set current slide classes');
//       items[oldPrevious].className = itemClassName;
//       console.log(items[oldPrevious]);
//       items[oldNext].className = itemClassName;

//       // add the new classes
//       console.log('set new slide classes');
//       items[newPrevious].className = itemClassName + ' prev';
//       items[slide].className = itemClassName + ' active';
//       items[newNext].className = itemClassName + ' next';
//       // }

//       // console.log('end move');
//     }
//   }
// }

// // next navigation handler
// function moveNext() {

//   // this.classList.toggle('clicked');
//   // console.log('clicked on next');
//   // check if moving
//   if (!moving) {

//     // if it's the last slide, reset to 0, else +1
//     if (slide === (totalItems - 1)) {
//       slide = 0;
//     } else {
//       slide++;
//     }

//     // move carousel to updated slide
//     moveCarouselTo(slide);
//   }
// }

// // previous navigation handler
// function movePrev() {

//   // this.classList.toggle('clicked');
//   // console.log('clicked on prev');
//   // check if moving
//   if (!moving) {

//     // if it's the first slide, set as the last slide, else -1
//     if (slide === 0) {
//       slide = (totalItems - 1);
//     } else {
//       slide--;
//     }

//     // move carousel to updated slide
//     moveCarouselTo(slide);
//   }
// }

// // initialise carousel
// function initCarousel() {
//   // if carousel has more than three items we're moving on
//   if (totalItems > 3) {
//     setInitialClasses();
//     setEventListeners();
//   }

//   // set moving to false now that the carousel is ready
//   moving = false;

//   // console.log(`${totalItems} slides`);
//   // console.log(`slide ${slide}`);
// }

// initCarousel();

// option 3: multiple carousel instances but not loopable
// https://codepen.io/brianmarco/pen/WNdaNov

const carousels = document.querySelectorAll(".carousel") || [];

carousels.forEach((carousel) => {
  // console.log(carousel);
  setupCarousel(carousel);
});

function setupCarousel(carousel) {
  const prev = carousel.querySelector(".carousel-pagination-prev");
  const next = carousel.querySelector(".carousel-pagination-next");
  const track = carousel.querySelector(".carousel-track");

  const slides = Array.from(track.children);
  const targetIndex = slides.findIndex;

  // arrage slides on the track next to eachother
  function setSlidePosition(slide, index) {
    slide.style.left = index * 100 + "%";
  }
  slides.forEach(setSlidePosition);

  // targetSlide is nextSlide/prevSlide param from the moveNext/movePrev functions
  function moveTrackToSlide(track, currentSlide, targetSlide) {
    track.style.transform = "translateX(-" + targetSlide.style.left + ")";
    currentSlide.classList.remove("is-active");
    targetSlide.classList.add("is-active");
  }

  // previous slide function
  prev.addEventListener("click", movePrev);

  function movePrev() {
    var currentSlide = track.querySelector(".is-active");
    var prevSlide = currentSlide.previousElementSibling;
    var prevIndex = slides.findIndex((slide) => slide === prevSlide);

    hideShowArrow(slides, prev, next, prevIndex);
    moveTrackToSlide(track, currentSlide, prevSlide);
  }

  // next slide function
  next.addEventListener("click", moveNext);

  function moveNext() {
    var currentSlide = track.querySelector(".is-active");
    var nextSlide = currentSlide.nextElementSibling;
    var nextIndex = slides.findIndex((slide) => slide === nextSlide);

    hideShowArrow(slides, prev, next, nextIndex);
    moveTrackToSlide(track, currentSlide, nextSlide);
  }

  //hide/show prev/next pagination if on first/last slide
  function hideShowArrow(slides, prev, next, targetIndex) {
    if (targetIndex === 0) {
      prev.classList.add("is-hidden");
      next.classList.remove("is-hidden");
    } else if (targetIndex === slides.length - 1) {
      prev.classList.remove("is-hidden");
      next.classList.add("is-hidden");
    } else {
      prev.classList.remove("is-hidden");
      next.classList.remove("is-hidden");
    }
  }
};

// more options & info:
// https://christianheilmann.com/2015/04/08/keeping-it-simple-coding-a-carousel/